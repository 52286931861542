//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations } from 'vuex'

import StockIndex from '~/components/Main/StockIndex'
import NewsFlash from '~/components/Main/NewsFlash'
import StockCalendar from '~/components/Main/StockCalendar'
import Login from '~/components/Main/Login'
import TodaysWorld from '~/components/Main/TodaysWorld'
import IncreaseThemeRanking from '~/components/Main/IncreaseThemeRanking'
// import IncreaseThemeRankingMonth from '~/components/Main/IncreaseThemeRankingMonth'

export default {
    name: 'Main',
    components: {
        StockIndex,
        NewsFlash,
        StockCalendar,
        Login,
        TodaysWorld,
        IncreaseThemeRanking,
        // IncreaseThemeRankingMonth,
    },
    computed: {
    },
    created() {

    },
    mounted() {
      // process.env.VUE_APP_REST_API
      console.log('process.env.VUE_APP_REST_API', process.env.NODE_ENV)
      // 22.05.16 suye 리뉴얼 관련 임시 팝업 
      // if (this.$cookies.get("popClose")!=='ok'){
        // window.open("https://new.infostock.co.kr/Renewal", "_blank", "width=500, height=450")
        // window.open("http://192.168.0.6/Renewal", "_blank", "width=500, height=450")
      // }
    },
    methods: {
      ...mapMutations('ui', ['SET_MOBILE_MENU_SHOW', 'SET_ISPOPUP_OPEN']),

    },
}
