//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex'

import CONSTANTS from '~/constants'
import api from '~/api/' // server
import storage, { keys } from '~/services/storage'

export default {
    name: 'Login',

    props: [],

    data() {
        return {
            id: '',
            password: '',
            checkedIdSave: false,
            // loginInfo: null,
        }
    },

    computed: {
        ...mapState('member', ['memberInfo']),
    },

    created() {
        console.log('memberInfo===',this.memberInfo)
        /**
         * localStorage 접근이 안되서 null을 리턴함
         * 그래서 beforeMount 에서 처리함
         */
        // const loginUserInfo = storage.get(keys.loginInfo) // 저잗된 값이 없으면 null
        // console.log('created loginUserInfo', loginUserInfo)
    },

    beforeMount() {
        // id 저장 여부 처리
        const savedId = storage.get(keys.id)
        if (savedId) {
            this.id = savedId
            this.checkedIdSave = true
        }

        /**
         * keys.loginInfo 정보가 저장되어 있으면 로그인 상태임
         */
        const loginInfo = storage.get(keys.loginInfo) // 저잗된 값이 없으면 null
        console.log('beforeMount loginInfo', loginInfo)

        // 회원정보 store에 갱신
        if (loginInfo) {
            this.getMemberInfo(loginInfo.id)
        }
    },

    methods: {
        ...mapActions('member', ['GET_MEMBER_INFO', 'LOGOUT']),
        ...mapActions('modal', ['SHOW_ALERT', 'SHOW_FIND_ID']),

        payPopup(){
            console.log('this.$store', this.$store)
        },
        goMypage(){
            this.$router.push('MyPage/MemberInfo')
        },              
        onClickLogin() {
            if (this.id === '') {
                this.SHOW_ALERT({ content: '아이디를 입력하세요.' })
                return
            } else if (this.id.length < 4) {
                this.SHOW_ALERT({
                    content:
                        '가입하지 않은 아이디이거나, 잘못된 비밀번호입니다.',
                })
                return
            }

            if (this.password === '') {
                this.SHOW_ALERT({ content: '비밀번호를 입력하세요.' })
                return
            }

            this.login()
        },

        onClickFindIdPwd() {
            // this.HIDE_MODAL()
            this.findId()
        },

        onClickLogout() {
            this.logout()
        },

        async login() {
            const params = {
                id: this.id,
                password: this.$CryptoJS.SHA1(this.password).toString(),
                // password: this.password,
            }

            console.log('login Params=', params)
            await api.login(params)
            .then((res)=>{
                console.log('login res', res)

                if (!res.success) {
                    this.SHOW_ALERT({ content: '로그인 실패' })
                }

                // modal 가입완료 표시
                else if (res.success) {
                    // this.SHOW_ALERT({ content: '로그인 성공' })

                    // 로그인 정보(로그인 유지 판단용) 스토리지에 저장
                    storage.set(keys.loginInfo, res.data)

                    // member store 갱신
                    this.getMemberInfo(this.id)

                    // id 저장
                    if (this.checkedIdSave) {
                        storage.set(keys.id, this.id)
                    } else {
                        storage.remove(keys.id)
                    }
                }
            })
            .catch((err)=>{ // 220816 meej main error page
                alert(err)
            })
        },

        getMemberInfo(id) {
            const params = {
                id,
                // id: 'testid4', // TEST
            }
            console.log('info params', id)
            this.GET_MEMBER_INFO(params)
        },

        logout() {
            /**
             * plugin/method.js 에 공통 method 사용
             */
            this.$method.logout(this.logoutCallback)
        },

        findId() {
            this.SHOW_FIND_ID()
        },

        logoutCallback() {
            this.resetInput()
        },

        resetInput() {
            console.log('resetInput')
            /* 20210518 shb 추가
            로그아웃 후 저장한 id가 있다면 input 창에 저장 id 넣어준다. */
            const savedId = storage.get(keys.id)
            if (savedId) {
                this.id = savedId
                this.checkedIdSave = true
            } else {
                this.id = ''
            }
            this.password = ''
        },
    },
}
